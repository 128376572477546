

.section {
	position: relative;
	width: 80%;
}

.sec-header {
	background-color: #fffe;
	min-height: 90px;
	padding: 20px 35px 20px 35px;
	border-radius: 5px;
	display: flex;
	flex-direction: column;
	position: relative;
	color: #3A103A;
}

.sec-header .row {
	display: flex;
	align-items: row;
	justify-content: space-between;
}

.sec-header .title {
	font-size: 30px;
	font-weight: 800;
	color: #511652;
	margin-right: 130px;
}

.sec-header hr {
	background-color: #F8DAEF;
	border: none;
	min-height: 4px;
	width: 100%;
	margin-left: 0;
}

.sec-header .sub {
	font-weight: 700;
	font-size: 16px;
	display: flex;
	
	margin-right: 0;
	margin-left: auto;
	
	align-items: center;
	justify-content: center;
	text-align: center;
	min-width: 220px;
	height: 36px;
	
	border: double 2px transparent;
	border-radius: 20px;
	background-image: linear-gradient(#fff,#fff), linear-gradient(150deg, rgba(121,74,255,1) 0%, rgba(235,0,255,1) 100%);
	background-origin: border-box;
	background-clip: content-box, border-box;
	
	cursor: pointer;
}

.sub a {
	background: linear-gradient(150deg, rgba(121,74,255,1) 0%, rgba(235,0,255,1) 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.sec-header .description {
	margin-top: 5px;
	font-weight: 500;
	display: flex;
	margin-top: auto;
	margin-bottom: auto;
}

.sec-row {
	display: flex;
	margin-top: 30px;
	gap: 50px;
}

.sec-row .posts {
	flex: 7;
}

.sec-info {
	flex: 3;
}

.sec-actions {
	display: flex;
}

.sec-actions .pages {
	display: flex;
	margin-bottom: 30px;
}

.sec-actions .page-btn {
	width: 32px;
	height: 24px;
	display: flex;
	align-items: center;
	justify-content: center;
	
	background-color: white;
	border-radius: 3px;
	
	font-size: 18px;
	font-weight: 800;
	
	cursor: pointer;
}

.sec-actions .topic-actions {
	margin-left: auto;
	margin-right: 0;
	display: flex;
	gap: 20px;
	height: 34px;
}

.topic-actions div {
	background-color: #FCFCFC;
	border-radius: 7px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 2px 22px 2px 22px;
	min-height: 32px;
	
	
	color: #511652;
	font-size: 15px;
	font-weight: 800;
	text-transform: uppercase;
	
	cursor: pointer;
}

.sec-actions div a {
	pointer-events: none;
	color: inherit;
	padding-top: 5px;
	padding-bottom: 5px;
}

.sec-topic {
	width: 100%;
	min-height: 72px;
	background-color: #fffe;
	position: relative;
	
	cursor: pointer;
	
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	
	font-family: 'Inter';
}

.sec-topic .right {
	display: flex;
	flex-direction: column;
	padding: 13px;
	margin-left: 8px;
	font-weight: 400;
	gap: 3px;
}

.sec-topic .authorname {
	color: #2FCDFF;
}

.sec-topic .right .top {
	font-size: 20px;
}

.sec-topic .left {
	display: flex;
	flex-direction: row-reverse;
	align-items: center;
	font-size: 14px;
}

.sec-topic .stats {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	color: #777;
	font-weight: 500;
	margin: 0 12px;
}

.sec-topic .last-reply {
	display: flex;
	flex-direction: column;
	margin: 0 12px;
	width: 130px;
}

.sec-topic .avatar {
	width: 40px;
	height: 40px;
	border-radius: 500px;
}

.sec-topic .replier {
	font-size: 15px;
}

.sec-topic .replied {
	color: #981373;
}

.section .info {
	display: flex;
	flex-direction: column;
	margin: 0 12px;
}



.sec-empty {
	background-color: #fffe;
	padding: 20px 35px 20px 20px;
	border-radius: 5px;
	display: flex;
	flex-direction: column;
	position: relative;
	color: #3A103A;
}

.sec-empty a {
	font-weight: 600;
	color: purple;
	cursor: pointer;
}





@media (max-width: 1100px) {
	.section .stats {
		display: none;
	}

}


@media (max-width: 800px) {
	.section-bar {
		font-size: 4vw;
	}
	
	.section {
		width: 100%;
	}
	
	.sec-row {
		flex-direction: column;
	}
	
	.section-bar {
		font-size: 1rem;
	}
	
	.section .sub {
		font-size: 11px;
		min-width: 140px;
		position: absolute;
		right: 14px;
		top: 14px;
	}
	
	.section .info {
		width: 100%;
		align-items: center;
		margin: 0;
	}
	
	.section .info-block {
		width: 100%;
	}
}