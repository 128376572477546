/* Below Full Page */

.forum {
	display: flex;
	align-items: center;
	flex-direction: column;
	background: url("../img/chicken.png"), linear-gradient(180deg, #db00ff10 30%, #4200ff22 100%), rgba(255,255,255,1);
	background-attachment: fixed;
	position: relative;
	padding-bottom: 70px;
}

.content {
	min-height: 1000px;
	width: 1300px;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	z-index: 0;
}

.forum .back {
	position: absolute;
	height: 100%;
	width: 100%;
	left: 0;
	background: url("../img/rect.png") repeat-y;
	background-position: 50% 0%;
}

/*.content .light-bg {
	position: absolute;
	background: url("../img/back.png") no-repeat;
	background-position: 0 70px;
	z-index: 0;
	width: 100%;
	height: 100%;
	left: 10%;
	bottom: 0;
	opacity: 0.3;
}*/

.theme-header {
	width: 100%;
}


.section-bar {
	min-height: 32px;
	width: 100%;
	align-self: center;
	display: flex;
	flex-direction: row;
	align-items: center;
	margin: 20px 0 20px 0;
	color: #493A72;
	font-weight: 1000;
	font-size: 20px;
	
	#white-space: nowrap;
	cursor: pointer;

	z-index: 1;
}

.flex-column .section-bar {
	width: 82%;
}

.section-bar img {
	top: 2px;
	position: relative;
	margin: 0 1px;
}

.section-bar .section-a {
	position: relative; 
	max-height: 32px;
	display: inline-block;
	align-items: center;
}

.section-bar .section-a a {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	margin: 0 3px;
	color: inherit;
}



.shadowed {
	box-shadow: 2px 2px 3px 1px #0002;
}





* {
	-webkit-tap-highlight-color: transparent;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus
input:-webkit-autofill, 
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid white;
  -webkit-text-fill-color: white;
  -webkit-box-shadow: 0px 0px 20px 2px #0f0 inset;
  transition: background-color 5000s ease-in-out 0s;
}

input {
	border-radius: 30px;
}

a {
	text-decoration: none;
}






@media (max-width: 1000px) { 
	.content {
		flex-direction: column;
		align-items: center;
		justify-content: start;
	}
	
	.rect-bg {
		display: none;
		background-image: '';
	}
}

@media (max-width: 1400px) { 
	.content {
		width: 95%;
	}
	
	.rect-bg {
		display: none;
	}
}