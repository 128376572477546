.sections {
	width: 70%;
	display: flex;
	flex-direction: column;
	z-index: 1;
	margin-right: 120px;
}

.theme-header {
	background-color: white;
	height: 50px;
	font-size: 24px;
	display: flex;
	align-items: center;
	border-radius: 10px;
	color: white;
	font-weight: 700;
	
	width: 102%;
	margin-left: -1%;
}

.theme-1 {
	background: linear-gradient(150deg, rgba(100,248,176,1) 0%, rgba(200,255,178,1) 100%);
}

.theme-2 {
	background: linear-gradient(150deg, rgba(255,208,153,1) 0%, rgba(255,255,119,1) 100%);
}

.theme-3 {
	background: linear-gradient(150deg, #D386F8 0%, rgba(185,178,255,1) 100%);
}

.theme-4 {
	background: linear-gradient(150deg, #53acff 0%, #33dcdf 100%);
}

.theme-5 {
	background: linear-gradient(150deg, #CC5500 0%, #555555 100%);
}

.theme-text-1 {
	background: linear-gradient(150deg, rgba(100,248,176,1) 0%, rgba(200,255,178,1) 100%), #aaa;
}

.theme-text-2 {
	background: linear-gradient(150deg, rgba(255,208,153,1) 0%, rgba(255,255,119,1) 100%), #aaa;
}

.theme-text-3 {
	background: linear-gradient(150deg, #D386F8 0%, rgba(185,178,255,1) 100%), #aaa;
}

.theme-text-4 {
	background: linear-gradient(150deg, #53acff 0%, #ffc582 100%), #aaa;
}

.theme-text-5 {
	background: linear-gradient(150deg, #CC5500 0%, #555555 100%), #aaa;
}

.theme-header a {
	margin-left: 22px;
}

.theme .section {
	min-height: 64px;
	width: 100%;
	background-color: #fffd;
	font-weight: 500;
	font-size: 18px;
	
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.theme .section .title-stats {
	display: flex;
	align-items: center;
	flex: 1;
	margin-right: 10px;
}

.theme .section-title {
	cursor: pointer;
}

.theme .icon {
	margin: 0 15px;
	cursor: pointer;
}

.theme .row-hr {
	width: 100%;
	height: 2px;
	background-color: #0001;
}

.theme .left {
	display: flex;
	align-items: center;
	
	margin-right: 0;
	margin-left: auto;
}

.theme .stat {
	margin: 10px;
	display: flex;
	flex-direction: column;
	align-items: center;
	font-family: Inter;
	user-select: none;
}

.theme .stat .top {
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	font-size: 14px;
}

.theme .stat .value {
	font-size: 18px;
}

.theme .last-reply {
	display: flex;
	flex-direction: row-reverse;
	justify-content: flex-end;
	align-items: center;
	font-size: 14px;
	width: 265px;
	user-select: none;
	cursor: pointer;
}

.theme .column {
	display: flex;
	flex-direction: column;
	margin: 0 14px;
}

.theme .avatar {
	min-width: 38px;
	height: 38px;
	border-radius: 500px;
}

.theme .title {
	font-weight: 700;
	font-size: 16px;
	color: #7f5060;
}

.theme .replier {
	font-size: 14px;
	white-space: nowrap;
	display: flex;
}

.theme .replied {
	color: #981373;
	width: 100px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.theme .mobile-replied-at {
	display: none;
	text-align: center;
	font-size: 12px;
}

.section-title {
	color: inherit;
}

/* Ааа */

.top-topic {
	display: flex;
	flex-direction: row;
	margin-bottom: 20px;
	cursor: pointer;
}

.top-topic .column {
	display: flex;
	flex-direction: column;
	gap: 2px;
	flex: 5;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.top-topic .title-row {
	font-size: 16px;
	font-weight: 400;
}

.top-topic .section-row {
	font-size: 16px;
	font-weight: 600;
}

.top-topic .icon {
	flex: 1;
	display: flex;
	margin-top: 4px;
	align-items: center;
	justify-content: center;
	font-size: 30px;
	font-weight: 800;
}

.top-topic .icon img {
	position: absolute;
	z-index: 0;
}

.top-topic .icon a {
	position: absolute;
	z-index: 1;
	margin-top: 4px;
	color: #eecf00;
	-webkit-text-stroke: 0.7px white;
	text-stroke: 0.7px white;
}

@media (max-width: 1000px) { 
	.sections {
		width: 90%;
		margin-right: 0;
	}
}

@media (max-width: 600px) { 
	.sections {
		margin-bottom: 20px;
		width: 100%;
	}
	
	.theme .icon {
		height: 26px;
		margin: 10px;
	}
	
	.theme .avatar {
		min-width: 30px;
		height: 30px;
		height: 30px;
	}
	
	.theme .section .title-stats {
		margin: 0;
	}
	
	.theme .last-reply {
		width: 64px;
		flex-direction: column;
	}
	
	.theme .replier { display: none; }
	.theme .title { display: none; }
	.theme .mobile-replied-at { display: flex; }
}

@media (max-width: 380px) { 
	.theme .last-reply {
		display: none;
	}
}
