.creation {
	margin-top: 20px;
	width: 82%;
	display: flex;
	flex-direction: column;
}

.creation .section-bar {
	margin: 0px 0 0px 0;
	align-self: flex-start;
}

.creation .topper {
	margin: 20px 0;
	background-color: #fffe;
	padding: 20px 35px 20px 35px;
	border-radius: 5px;
	display: flex;
	flex-direction: column;
	position: relative;
	color: #3A103A;
}

.creation .topper a {
	color: purple;
	cursor: pointer;
}

.creation .title {
	display: flex;
	justify-content: space-between;
}

.creation .title .symbols-left {
	font-family: Inter;
	font-weight: 800;
	font-size: 17px;
	color: purple;
	
	display: flex;
	align-items: end;
}

.creation .title-input {
	position: relative;
	margin: 10px 0 20px 0;
	width: calc(100% - 2px);
	height: 40px;
	
	display: flex;
}

.creation .title-input input {
	width: 100%;
	flex: 1;
	
	background: none;
	border: none;
	outline: none;
	text-indent: 12px;
	font-size: 21px;
	font-weight: 600;
	font-family: Inter;
	
	display: flex;
	align-items: center;
	
	
	
	z-index: 2;
}

.creation .title-input .background {
	position: absolute;
	z-index: -1;
	inset: 0;
	padding: 3px;
	border-radius: 5px;
	background: linear-gradient(to right, rgba(121,74,255,1) 0%, rgba(235,0,255,1) 100%);
	-webkit-mask: 
	linear-gradient(#fff 0 0) content-box, 
	linear-gradient(#fff 0 0);
	  mask: 
	linear-gradient(#fff 0 0) content-box, 
	linear-gradient(#fff 0 0);
	-webkit-mask-composite: xor;
	mask-composite: exclude;
	position: absolute;
	height: 100%;
	width: calc(100% - 2px);
	margin-top: -2px;
}

.creation .title-input .white-background {
	position: absolute;
	z-index: -2;
	background-color: #fff;
	opacity: 0.7;
	width: 100%;
	height: 100%;
}

.creation .textbox {
	height: 320px;
	min-height: 320px;
	max-height: 400px;
}


@media (max-width: 600px) { 
	.creation {
		width: 100%;
	}
}