.profiles {
	height: 100%;
	
	display: flex;
	align-items: center;

	flex-direction: column;
	
	font-family: Inter;
	paint-order: stroke fill;
	
	image-rendering: pixelated;
	
	overflow-y: scroll;
}

.profiles .column {
	display: flex;
	flex-direction: column;
}

.profiles .row {
	display: flex;
	flex-direction: row;
	gap: 50px;
	
	position: relative;
}

.profiles .title {
	font-size: 22px;
	-webkit-text-stroke: 3px #663D00;
	min-height: 32px;
	max-height: 32px;
	margin-bottom: 7px;
	color: white;
	font-weight: 800;
	text-align: center;
}

.profiles .border {
	border-image: url("acacia_log.png") 1;
	border-image-width: 3px;
	border-image-outset: 3px;
}

/*           */
/* Вернуться */
/*           */
.profiles .go-back {
	width: 110px;
	padding: 5px;
	margin: 30px 0;
	
	display: flex;
	justify-content: center;
	
	color: white;
	font-size: 18px;
	font-weight: 700;
	
	background-image: url("acacia_log.png");
	background-size: 50px;
	-webkit-text-stroke: 4px #663D00;
	
	border-image: url("dark_oak_log.png") 1.5;
	border-image-width: 4px;
	border-image-outset: 4px;
	
	cursor: pointer;
}

/*         */
/* Аккаунт */
/*         */

.profiles .account {
	display: flex;
	flex-direction: column;
	width: 400px;
	position: relative;
}

.profiles .account .rose {
	position: absolute;
	left: 30px;
	top: -20px;
	width: 30px;
}

.profiles .account .lamp {
	position: absolute;
	right: 30px;
	bottom: -69px;
	width: 40px;
}

.profiles .account .top {
	width: 100%;
	height: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	
	font-size: 22px;
	font-weight: 900;
	color: #FFE485;
	
	background-image: url("grass_block_side.png");
	background-size: 50px;
	
	-webkit-text-stroke: 4px #663D00;
}

.profiles .account .bottom {
	padding: 0px 25px 20px 25px;
	
	display: flex;
	flex-direction: column;
	
	font-size: 20px;
	font-weight: 700;
	color: white;
	
	background-image: url("dirt.png");
	background-size: 50px;
}

.profiles .account .bottom a {
	margin: 0;
	margin-top: 0px;
	-webkit-text-stroke: 2px #663D00;
}


/*         */
/* Профиль */
/*         */

.profiles .profile {
	display: flex;
	flex-direction: column;
	width: 350px;
}

.profiles .profile .top {
	width: 100%;
	height: 36px;
	display: flex;
	justify-content: center;
	align-items: center;
	
	font-size: 22px;
	font-weight: 900;
	color: #FFBDF8;
	
	background-image: url("acacia_log.png");
	background-size: 50px;
	
	-webkit-text-stroke: 2px #A96602;
}

.profiles .profile .bottom {
	padding: 20px 25px;
	
	display: flex;
	flex-direction: column;
	
	font-size: 19px;
	font-weight: 700;
	color: white;
	
	background-image: url("cherry_planks.png");
	background-size: 50px;
	
	-webkit-text-stroke: 2px #663D00;
}

.profiles .rank {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 5px;
	margin-bottom: 5px;
}

.profiles .rank .text {
	margin-top: -3px;
}

.profiles .rank .name {
	font-size: 17px;
	font-weight: 1000;
	color: #66ff66;
}

@media (max-width: 800px) { 
	.profiles .row {
		flex-direction: column;
		align-items: center;
	}
}

/*        */
/* Кнопки */
/*        */

.profiles .buttons {
	margin-top: 80px;
}

.profiles .button {
	position: relative;
	
	padding: 8px;
	margin-top: 2px;
	display: flex;
	justify-content: center;
	color: white;
	font-size: 18px;
	font-weight: 700;
	-webkit-text-stroke: 2px #8D4545;
	
	cursor: pointer;
	
	border-image: url("dark_oak_log.png") 1;
	border-image-width: 3px;
	border-image-outset: 3px;
	
	background-image: url("oak_planks.png");
	background-size: 50px;
}

.profiles .button a {
	pointer-events: none;
	user-select: none;
}

/*                          */
/* Инвентарь (предпросмотр) */
/*                          */

.profiles .inventory {
	position: absolute;
	right: 0;
}

.profiles .inventory .inside {
	height: 75px;
	width: 250px;
	margin: 10px 0;
	
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	
	background-image: url("birch_planks.png");
	background-size: 50px;
}

.profiles .inventory .slot {
	height: 60px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	flex-direction: column;
	gap: 5px;
	
	color: white;
	font-size: 19px;
	font-weight: 1000;
	-webkit-text-stroke: 4px #8D4545;
}

.profiles .slot img {
	image-rendering: smooth;
	width: 36px;
}

@media (max-width: 800px) { 
	.profiles .inventory {
		position: relative;
	}
}