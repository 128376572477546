.auth {
	position: absolute;
	height: 100%;
	width: 100%;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	
	display: none;
	opacity: 0;
	margin-top: 100px;
	
	overflow: overlay;
}

/*.top-title {
	font-size: 92px;
	font-weight: 1000;
	font-family: Kanit;
	height: 120px; 
	margin-top: 10vh;
}

.top-title a {
	background-image: linear-gradient(180deg, rgba(219,190,255,1) 40%, rgba(228,255,227,1) 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.top-title a:before {
	position: absolute;
	content: 'ARTWORLD';
	padding: 1px;
	margin: -1px;
	
	background: rgb(68,3,151);
	background: linear-gradient(90deg, rgba(68,3,151,1) 0%, rgba(0,255,240,1) 100%);

	-webkit-text-stroke: 4px transparent;
	-webkit-text-fill-color: transparent;
	-webkit-background-clip: text;
	
	z-index: -1;
}*/

.auth-route-text {
	font-size: 40px;
	font-weight: 1000;
	font-family: Inter;
	
	z-index: 2;
}

.auth-route-text a {
	background: rgb(177,150,255);
	background: linear-gradient(180deg, rgba(255,255,255,1) 20%, rgba(100,255,218,1) 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.auth-route-text a:before {
	content: "";
	position: absolute;
	background: #4E2494;
	
	padding: 1px;
	margin: -1px;
	
	-webkit-text-stroke: 4px transparent;
	-webkit-text-fill-color: transparent;
	-webkit-background-clip: text;
	
	z-index: -1;
}

.panel-login {
	margin-top: 100px;
	
	flex-direction: column;
	justify-content: space-between;
	
	font-weight: 800;
	font-size: 16px;
	color: white;
	
	letter-spacing: 0.5px;
	z-index: 1;
}

.auth .back {
	width: 100%;
	text-align: center;
	font-size: 22px;
	margin-top: 14px;
	cursor: pointer;
}

.auth .error-text {
	margin: 8px;
	height: 4px;
	font-size: 16px;
	color: #FFB4B4;
	filter: drop-shadow(2px 0 4px #f884);
	display: flex;
	display: flex;
	justify-content: center;
}

.auth .fields {
	width: 84%;
	height: auto;
	
	display: flex;
	flex-direction: column;
	justify-content: center;
	
	font-size: 19px;
	
	margin: 20px 7%;
}

.auth .fields .field {
	flex: 1;
	
	background: none;
	border: none;
	color: white;
	outline: none;
	text-indent: 24px;
	font-size: 22px;
}

.form {
	position: relative;
	width: 100%;
	
	height: 58px;
	margin: 10px 0 0 0;
	border-radius: 32px;
	
	flex: 1;
	display: flex;
}

.auth .fields .gradient {
	position: absolute;
	z-index: -1;
	inset: 0;
	padding: 4px;
	border-radius: 40px;
	background: linear-gradient(to right, white, white);
	-webkit-mask: 
	linear-gradient(#fff 0 0) content-box, 
	linear-gradient(#fff 0 0);
	  mask: 
	linear-gradient(#fff 0 0) content-box, 
	linear-gradient(#fff 0 0);
	-webkit-mask-composite: xor;
	mask-composite: exclude;
	animation: hue 10s linear infinite;
}

@keyframes hue {   
  0% {     
    filter: hue-rotate(0);
  }   
  25% {
    filter: hue-rotate(90deg);
  } 
  50% {
    filter: hue-rotate(0deg);
  }
  75% {
    filter: hue-rotate(-90deg);
  }
  0% {     
    filter: hue-rotate(0);
  }
}

.auth .fields .field-title {
	margin-left: 10px;
}


/*.password::selection {
	color: #fff0;
}

.password-safe {
	position: absolute;
	left: 50px;
	max-width: 300px;
	overflow: hidden;
	z-index: -1;
	color: #fff;
	font-weight: 500;
	margin-bottom: 5px;
}*/

.auth .password-peek {
	position: absolute;
	height: 100%;
	width: 30px;
	right: 20px;
	cursor: pointer;
	opacity: 0.8;
}

.panel-login .pass-recovery {
	margin-top: -8px;
	background: rgb(255,252,188);
	background: linear-gradient(90deg, rgba(240, 255, 219, 1) 20%, rgba(255,252,188,1) 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	width: 70%;
	font-size: 18px;
}

.auth .email-info {
	position: absolute;
	height: 100%;
	width: 30px;
	right: 20px;
	cursor: pointer;
	opacity: 0.8;
	z-index: 2;
}

.email-info-text {
	position: absolute;
	font-size: 11px;
	display: flex;
	flex-direction: column;
	padding: 10px;
	border-radius: 30px;
	text-align: center;
	margin: 0px 0 90px 90px;
	width: 280px;
	z-index: 3;
	background: rgb(149,72,185);
	background: linear-gradient(70deg, #FABFFFCC 0%, #A396F1CC 100%);
	opacity: 0.9;
	font-weight: 500;
	display: none;
	bottom: -30px;
}

.auth .clickable {
	font-weight: 1000;
	letter-spacing: 0;
	cursor: pointer;
	
	background: rgb(241,172,150);
	background: linear-gradient(90deg, #FABFFF 20%, #A396F1 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.panel-login .reg {
	padding-bottom: 10px;
	text-align: center;
}

.panel-login .reg {
	display: flex;
	gap: 6px;
	justify-content: center;
}

.panel-register {
	margin-top: 100px;
	
	display: none;
	opacity: 0;
	
	align-items: center;
	flex-direction: column;
	justify-content: space-between;
	
	font-weight: 800;
	font-size: 16px;
	color: white;
	
	letter-spacing: 0.5px;
	z-index: 1;
}

.panel-register .reg {
	margin-bottom: 15px;
	text-align: center;
}

.panel-register .continue {
	width: 180px;
	height: 50px;
	margin: 0 auto;
	margin-top: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.panel-login .continue {
	width: 120px;
	height: 52px;
	margin: 0 auto;
	margin-top: 30px;
	font-weight: 700;
}

.auth .panel .continue {
	position: relative;
	color: white;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 5px;
}

.auth .panel .continue:before {
	content: "";
	position: absolute;
	z-index: -1;
	inset: 0;
	padding: 6px;
	border-radius: 40px;
	background: linear-gradient(to right, #794AFF, #EB00FF);
	-webkit-mask: 
	linear-gradient(#fff 0 0) content-box, 
	linear-gradient(#fff 0 0);
	  mask: 
	linear-gradient(#fff 0 0) content-box, 
	linear-gradient(#fff 0 0);
	-webkit-mask-composite: xor;
	mask-composite: exclude;
}


.auth .panel {
	width: 420px;
	max-width: 420px;
	
	height: auto;
	background: #9548B9cc;
	border-radius: 30px;
	display: none;
	opacity: 0;
	
	margin-bottom: 100px;
}

.verify-text {
	display: flex;
	flex-direction: column;
	padding: 10px 40px 0 40px;
	font-size: 16px;
	font-weight: 600;
	margin-bottom: 5px;
}


.checkbox {
	margin-bottom: 4px;
	display: flex;
	align-items: center;
	font-size: 16px;
	font-weight: 500;
	gap: 8px;
}

.checkbox input {
	border: 1px white;
	width: 12px;
	height: 12px;
	-webkit-appearance: none;
	background-color: #fff0;
	border: 2px solid #fff;
	border-radius: 0;
	display: inline-block;
	position: relative;
}

.checkbox input:checked {
	box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px -15px 10px -12px rgba(0,0,0,0.05), inset 15px 10px -12px rgba(255,255,255,0.1);
	color: #99a1a7;
}

.checkbox input:checked:after {
	content: '\2714';
	font-size: 16px;
	position: absolute;
	bottom: -2px;
	left: -1px;
	color: #fff;
}




@media (max-width: 600px) {
	.auth-route-text {
		margin-top: 90px;
		font-size: 6.5vw;
	}
	
	.auth .panel {
		width: 85%;
		#height: 50%;
		font-size: 14px;
	}
	
	.panel-login .fields .field {
		margin: 16px 0;
	}
	
	.auth .panel {
		height: auto;
		font-size: 16px;
	}
	
	.email-info-text {
		margin-left: 50px;
	}
}

@media (max-width: 400px) {
	.auth .panel {
		width: 100%;
		border-radius: 0;
	}
	
	.email-info-text {
		margin: 0;
		margin-bottom: 90px;
		right: 0;
	}
}