.footer {
	height: 64px;
	width: 100%;
	background-color: #372B59fa;
	color: #fff2;
	font-weight: 700;
	text-align: center;
	display: flex;
	justify-content: center;
	flex-direction: column;
}