.search-bar {
	width: 225px;
	height: 45px;
	border-radius: 10px;
	background-color: #fff4;
	position: absolute;
	bottom: 0;
	left: 10%;
	
	cursor: pointer;
	display: flex;
	
	color: white;
	font-size: 18px;
	font-weight: 700;
	
	z-index: 5;
}

.search-bar img {
	padding: 5px;
	width: 48px;
	margin: 0 4px;
	user-select: none;
}

.search-bar .input {
	width: 100%;
	
	cursor: pointer;
	
	display: flex;
	align-items: center;
}

.search-bar #placeholder {
	user-select: none;
}

.search-bar input {
	outline: none;
	border: none;
	position: absolute;
	background-color: #fff0;
	
	color: white;
	font-size: 18px;
	font-weight: 700;
	
	width: 85%;
}

.search-bar * {
	pointer-events: none;
}

.search-bar .hints {
	opacity: 0;
	display: none;
	flex-direction: column;
	
	font-weight: 400;
	font-size: 16px;
	
	position: absolute;
	margin-top: 50px;
	width: 40vw;
	min-height: 45px;
	background-color: #0004;
	backdrop-filter: blur(3px);
	border-radius: 20px;
}

.search-bar .result {
	height: 45px;
	display: flex;
	align-items: center;
	margin-left: 20px;
	width: 95%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	user-select: pointer;
	pointer-events: all;
}

.search-bar .result .bold {
	font-weight: 600;
	background-color: #0f05;
}

@media (max-width: 1200px) {
	.search-bar .hints {
		width: 90vw;
	}
}

@media (max-width: 1300px) {
	.search-bar {
		left: 20px;
	}
}
@media (max-width: 700px) {
	.search-bar {
		left: 5px;
	}
}