.topic {
	position: relative;
	display: flex;
	font-family: Inter;
	
	margin-bottom: 20px;
	background: #fff;
	z-index: 1;
	
	height: auto;
	width: 80%;
	align-self: center;
}

.topic.main-post {
	min-height: 300px;
	width: 81%;
	margin-bottom: 40px;
}

.light-back-line:before {
	#content: '';
	position: absolute;
	left: -30vw;
	width: 110vw;
	height: 100%;
	background: #fff3;
	z-index: -2;
}

.topic .author {
	min-width: 18%;
	padding: 0 10px 0 10px;
	margin-right: 20px;
	background: radial-gradient(#bbb7, #fff0);
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-top: 20px;
	
	font-family: Inter;
	font-size: 20px;
	font-weight: 600;
	color: #666;
	
	
}

.topic .author .avatar {
	width: 90px;
	height: 90px;
	margin: 10px 0;
	border-radius: 500px;
	border: 4px solid #777f;
}

.topic .author.online {
	color: #69339E;
	background: radial-gradient(#794AFF22, #fff0);
}



.topic .author.online .avatar {
	border: 3px solid #5923FE99;
}

.topic .user-rank {
	font-size: 18px;
}

.topic .user-stats {
	margin: 8px 0;
	display: flex;
	align-items: center;
	gap: 5px;
	font-size: 16px;
	font-weight: 400;
	padding-bottom: 10px;
}

.topic .user-stats img {
	height: 18px;
}




.topic-content {
	display: flex;
	flex-direction: column;
	width: 78%;
	padding: 20px 20px 10px 0px;
	font-size: 15px;
}

.topic-content .text-only {
	user-select: text;
}

.topic .timestamp {
	color: #CBCBCB;
	font-size: 14px;
	font-weight: 900;
	text-transform: uppercase;
}

.topic .reactions {
	margin-top: auto;
	margin-bottom: 2px;
	width: 100%;
}

.topic .reactions .hr {
	height: 2px;
	margin: 8px auto;
	width: 90%;
	opacity: 0.1;
	background-color: black;
}

.topic .show-reactions {
	position: absolute;
	bottom: 10px;
	right: 10px;
}

.topic .show-reactions img {
	cursor: pointer;
	opacity: 0.8;
	height: 24px;
}

.topic .reactions .icons {
	height: 32px;
}


.topic .reactions .block {
	width: 170px;
	height: 32px;
	border-width: 1px;
	border-style: solid;
	border-image: linear-gradient(150deg, rgba(121,74,255,1) 0%, rgba(235,0,255,1) 100%) 1;
	margin-left: auto;
	margin-right: -8px;
	
	display: none;
	align-items: center;
	justify-content: center;
	gap: 10px;
	background-color: #fff;
}

.topic .reactions .block a {
	background: linear-gradient(150deg, rgba(121,74,255,1) 0%, rgba(235,0,255,1) 100%);
	font-weight: 600;
	font-size: 23px;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	display: flex;
	align-items: center;
	gap: 3px;
}



.topic .show-actions {
	position: absolute;
	top: 10px;
	right: 10px;
}

.topic .show-actions img {
	cursor: pointer;
	opacity: 0.8;
}

.show-actions .actions-block {
	position: absolute;
	display: none;
	opacity: 0;
	flex-direction: column;
	background-color: #fff;
	width: 150px;
	margin-top: 6px;
	right: 0;
	border-width: 2px;
	border-style: solid;
	border-image: linear-gradient(150deg, rgba(121,74,255,1) 0%, rgba(235,0,255,1) 100%) 1;
}

.show-actions .actions-row {
	width: 150px;
	height: 38px;
	display: flex;
	font-size: 17px;
	font-weight: 600;
	
	background: linear-gradient(150deg, rgba(121,74,255,1) 0%, rgba(235,0,255,1) 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	cursor: pointer;
}

.actions-block a {
	flex: 2.5;
	display: flex;
	align-items: center;
}

.actions-block .icon {
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: center;
}





.interact {
	display: flex;
	flex-direction: column;
	margin-top: 50px;
	width: 87%;
	align-self: center;
}

.flex-column {
	display: flex;
	flex-direction: column;
	width: 100vw;
}

.reply-title {
	margin-bottom: 8px;
}

.editable .textbox::-webkit-scrollbar {
	width: 14px;
}
.editable .textbox::-webkit-scrollbar-track {
  background: #fff5f577;
}
.editable .textbox::-webkit-scrollbar-thumb {
  background: linear-gradient(150deg, rgba(128,70,155,0.7) 0%, rgba(155,109,123,0.7) 100%);
  border-radius: 0px;
  border: 1px solid #ffffff33;
}

.editor {
	flex: 1;
	z-index: 1;
}

.editor .buttons {
	display: flex;
	width: calc(100% - 2px);
	flex-wrap: wrap;
	
	background-color: #FCFCFC;
	position: relative;
	
	border-width: 2px;
	border-style: solid;
	border-image: linear-gradient(150deg, rgba(121,74,255,1) 0%, rgba(235,0,255,1) 100%) 10;
	
	z-index: 2;
}

.editor .editable {
	
	position: relative;
	
	background-color: #F1F1F1;
	
	z-index: 1;
}

.editable .textbox {
	padding: 0 20px;
	height: 230px;
	min-height: 230px;
	max-height: 400px;
	overflow-y: scroll;
	overflow-x: hidden;
	
	scrollbar-color: #fff5f5aa #ffffff33;
	outline: none;
}

.editable .input-block {
	background-color: #E2E2E2;
	#backdrop-filter: blur(1px);
	position: absolute;
	height: 100%;
	width: 100%;
	left: 0;
	top: 0;
	
	display: flex;
	align-items: center;
	justify-content: center;
	
	font-size: 24px;
	font-weight: 500;
	
	user-select: none;
}

.editable .input-block img {
	margin-left: -20px;
}

.input-block .row div {
	font-size: 20px;
}

.input-block .row .go-auth {
	color: #8F00FF;
	background-color: #DFC6FF;
	border-radius: 5px;
	padding: 0 4px 0 4px;
	cursor: pointer;
}


.editor .icon {
	z-index: 1;
	width: 48px;
	height: 38px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.editor .icon a {
	font-weight: 1000;
	background: linear-gradient(150deg, rgba(121,74,255,1) 0%, rgba(235,0,255,1) 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.preview {
	width: 100%;
	height: 50px;
	background: #FCFCFC;
	z-index: 2;
	display: flex;
	align-items: center;
	box-shadow: 0 3px 3px #888;
}

.preview .edit-text {
	font-weight: 700;
	font-size: 20px;
	background: linear-gradient(150deg, rgba(121,74,255,1) 0%, rgba(235,0,255,1) 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	margin: 0 20px;
	user-select: none;
}

.preview .right {
	margin-left: auto;
	margin-right: 20px;
	display: flex;
	gap: 20px;
	align-items: center;
}

.preview .symbols {
	color: #999;
	font-weight: 600;
}

.preview .send {
	font-weight: 600;
	font-size: 20px;
	
	display: flex;
	align-items: center;
	justify-content: center;
	width: 180px;
	height: 34px;
	
	border: double 1px transparent;
	border-radius: 20px;
	background-image: linear-gradient(white,white), linear-gradient(150deg, rgba(121,74,255,1) 0%, rgba(235,0,255,1) 100%);
	background-origin: border-box;
	background-clip: content-box, border-box;
	
	cursor: pointer;
}

.preview .send a {
	background: linear-gradient(150deg, rgba(121,74,255,1) 0%, rgba(235,0,255,1) 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.topic .preview {
	box-shadow: none;
}

.topic.edit-state .author {
	margin: 0;
}
.topic.edit-state .topic-content {
	flex: 1;
}






.go-section {
	display: flex;
	justify-content: space-between;
	position: relative;
	margin: 20px 0 20px -25px;
}

.page-select {
	width: auto;
	height: auto;
	
	display: flex;
	justify-content: space-between;
}

.page-select .page-btn {
	width: 36px;
	height: 36px;
	
	display: flex;
	align-items: center;
	justify-content: center;
	
	color: white;
	font-size: 20px;
	font-weight: 900;
	
	border-width: 2px;
	border-style: solid;
	border-image: linear-gradient(150deg, rgba(121,74,255,1) 0%, rgba(235,0,255,1) 100%) 10;
	opacity: 0.8;
	
	cursor: pointer;
}

.page-select .page-btn.active {
	font-size: 22px;
	opacity: 1;
}




.watchers-content {
	width: 100%;
	min-height: 80px;
	margin-top: 10px;
	margin-bottom: 50px;
	z-index: 2;
}

.watchers-content div {
	padding: 20px;
	font-weight: 300;
	font-size: 18px;
	background-color: #F1F1F1;
	#filter: drop-shadow(0px 3px 3px #888);
}

.outbox-text {
	color: #524A4A;
	font-weight: 800;
	font-size: 20px;
}





@media (max-width: 1000px) {
	.topic .author .avatar {
		width: 10vw;
		height: 10vw;
	}
}

@media (max-width: 600px) {
	.topic-content {
		width: 60%;
	}
	.topic .timestamp {
		font-size: 12px;
	}
}

@media (max-width: 600px) {
	.preview {
		flex-direction: column;
		height: 70px;
		align-items: baseline;
		text-align: center;
		justify-content: center;
	}
	
	.editor .buttons {
		justify-content: space-evenly;
	}
	
	.topic {
		width: 100%;
	}
	
	.flex-column .section-bar {
		width: 96%;
	}
	
	.topic.main-post {
		width: 100%;
	}
	
	.topic .author {
		min-width: 23%;
	}
	
	.topic .author .avatar {
		width: 64px;
		height: 64px;
	}
	
	.interact {
		width: 99%;
	}
	
	.outbox-text {
		position: relative;
		left: 24px;
	}
}