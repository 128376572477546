body {
	margin: 0;
}

body * {
	text-decoration: none;
}

.page::-webkit-scrollbar {
	width: 0;
}

.page {
	scrollbar-width: none;
	font-family: "Raleway", sans-serif;
	scroll-behavior: smooth;
}

.page {
	position: absolute;
	flex-direction: column;
	height: 100%;
	width: 100%;
	overflow-x: hidden;
}

.box-shadow {
	-webkit-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
	-moz-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
	box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
}

/*body::-webkit-scrollbar-track {
  background: #151B3D;
}
body::-webkit-scrollbar-thumb {
  background: linear-gradient(150deg, rgba(228,170,255,1) 0%, rgba(255,209,223,1) 100%);
  border-radius: 0px;
  border: 4px solid #151B3D;
}*/

.special {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: #33333399;
	z-index: 5;
	display: none;
	
	display: none;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	
	color: white;
	font-size: 64px;
}

.kanit-black {
	font-family: "Kanit", sans-serif;
	font-weight: 900;
	font-style: normal;
}

::selection {
    background: #FF009933;
    color: #fff;
    text-shadow: none;
}

.header {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 300px;
}

.header .background {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 40%;
	display: flex;
	
	background: url("../img/back-full.jpg");
	background-size: fit;
	background-repeat: no-repeat;
	background-position: 60% 50%;
	
	z-index: -4;
}

.header .background-profiles {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	
	background: url("../img/4-back-full.png");
	background-size: fit;
	
	z-index: -5;
}

.header .darking {
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100vh;
	z-index: -1;
	background: rgba(21, 27, 61, 0.25);
	
	opacity: 1;
}

.top-header {
	width: 100%;
	height: 60px;
	background-color: rgba(0,0,0,0.25);
	display: flex;
	align-items: center;
	color: white;
	font-size: 23px;
	font-family: "Inter", sans-serif;
	font-weight: 800;
}

.top-header .indicator {
	display: flex;
	gap: 25px;
	margin-left: 30px;
	
	font-weight: 800;
}

.top-header .swift {
	display: flex;
	gap: 10px;
	flex-direction: row-reverse;
	justify-content: right;
	flex-wrap: nowrap;
	flex: 1;
	margin-right: 5%;
	z-index: 1;
}

.top-header .swift .play {
	margin-right: 40px;
	min-height: 40px;
	min-width: 140px;
	max-height: 40px;
	max-width: 140px;
	font-size: 22px;
	color: #234E39;
	background: linear-gradient(150deg, rgba(190,139,255,1) 0%, rgba(221,124,255,1) 100%);
	border-radius: 12px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

.top-header .swift div {
	display: flex;
	align-items: center;
	cursor: default;
}

.top-header .swift .max-size-el {
	padding: 0 15px;
}

.header .server-title {
	width: 100%;
	flex-grow: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 800;
	color: white;
	white-space: nowrap;
	position: relative;
}

.header .server-title .icon {
	background-size: cover;
}

.header .server-title .row {
	display: flex;
	flex-direction: column;
	user-select: none;
}

.header .server-title .network {
	background: linear-gradient(150deg, #FEE5FF 0%, #F5E9FF 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	
	font-size: 48px;
	cursor: pointer;
}

.header .server-title .network:before {
	position: absolute;
	content: 'ARTWORLD NETWORK';
	
	padding: 1px;
	margin: -1px;
	
	background: rgb(68,3,151);
	background: linear-gradient(90deg, #794AFF 0%, #EB00FF 100%);

	-webkit-text-stroke: 4px transparent;
	-webkit-text-fill-color: transparent;
	-webkit-background-clip: text;
	
	z-index: -1;
	
	font-size: 48px;
}

.header .server-title a {
	margin-top: -14px;
	background: linear-gradient(90deg, rgba(148, 165, 255, 1) 0%, rgba(171, 250, 255, 1) 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	font-size: 28px;
	font-weight: 1000;
	letter-spacing: 0.2px;
	paint-order: stroke fill;
}

.header .server-title a:before {
	position: absolute;
	content: 'НОВЫЙ ИГРОВОЙ ПРОЕКТ';
	
	padding: 1px;
	margin: -1px;
	
	background: rgb(68,3,151);
	background: linear-gradient(180deg, #5DE0D855 0%, #0005 100%);

	-webkit-text-stroke: 4px transparent;
	-webkit-text-fill-color: transparent;
	-webkit-background-clip: text;
	
	z-index: -1;
	
	font-size: 28px;
}

.header .server-title .text {
	cursor: pointer;
}

.forum-search {
	position: relative;
	bottom: 40px;
	left: 320px;
	width: 160px;
	height: 57px;
	border-radius: 8px;
	background-color: #ffffff44;
	display: flex;
	align-items: center;
	justify-content: center;
}

.forum-search:hover {
	opacity: 1;
}

.forum-search .icon {
	width: 40px;
	height: 40px;
	background: url("../img/svg/lupa.svg");
	background-repeat: no-repeat;
	background-size: cover;
	margin-right: 10px;
}

.forum-search .text {
	display: flex;
	flex-direction: column;
	color: #ffffff;
	font-weight: 600;
	font-family: Inter;
	font-size: 16px;
}

/* Forum Panel */

.panel {
	height: 60px;
	background: #372B59;
	#background: rgb(135,57,163);
	#background: linear-gradient(150deg, rgba(135,57,163,1) 0%, rgba(21,27,61,1) 100%);
	color: white;
	font-size: 24px;
	display: flex;
	width: 100%;
	
	position: sticky;
	top: 0;
	
	font-family: "Inter", sans-serif;
	font-weight: 500;
	z-index: 1;
}

.menu {
	position: relative;
	
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 1px;
	height: 100%;
	flex: 70;
	
	font-weight: 600;
	font-size: 23px;
}

.menu .swimming-point {
	width: 7px;
	height: 7px;
	border-radius: 5px;
	background-color: #FF35DF;
	position: absolute;
	opacity: 0;
}

.menu .btn-main {
	margin-left: 4px;
	margin-right: 4px;
	border: 3px solid rgba(0,0,0,0);
	padding: 6px 30px 6px 30px;
	border-radius: 50px;
}

.menu a {
	cursor: pointer;
}

/*.menu div.active:not(:hover) {
	#animation: menu 3s infinite alternate;
}*/


.get-ip {
	flex: 29.5;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
}

.get-ip-clickable {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	cursor: pointer;
	user-select: none;
}

.get-ip .click-to-copy {
	font-size: 14px;
	font-weight: 700;
	color: #D693FF;
}

.get-ip .server-ip {
	margin-top: -10px;
	font-weight: 1000;
	font-size: 31px;
	background: linear-gradient(27deg, rgba(228,170,255,1) 0%, rgba(255,209,223,1) 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.get-ip-row:active {
	cursor: default;
}

@media (max-width: 1300px) { 
	.get-ip {
		position: absolute;
		right: 16px;
		bottom: 72px;
	}
	
	.get-ip .click-to-copy {
		color: white;
		font-style: normal;
		margin-right: 0;
	}
	
	.get-ip .server-ip {
		color: white;
	}
	
	.get-ip-clickable {
		width: 100%;
	}
}

@media (max-width: 900px) { 
	.menu-btn {
		font-size: 10vw;
	}
}

@media (max-width: 700px) { 
	.top-header .swift {
		width: 100%;
	}
	
	.top-header .swift .play {
		#margin-right: 105px;
	}
	
	.top-header .swift {
		margin-bottom: 5px;
	}
	
	.get-ip-clickable {
		opacity: 0;
	}
}

@media (max-width: 900px) {
	.menu .btn-main {
		margin: 0;
		padding: 0;
		width: 20%;
		text-align: center;
	}
}

@media (max-width: 600px) { 
	.menu .btn-main {
		width: 25%;
	}
	
	
	.header .server-title .row {
		text-align: center;
	}
	
	.header .server-title .network:before {
		font-size: 8vw;
	}
	
	.header .server-title .network {
		font-size: 8vw;
	}
	
	.header .server-title a {
		margin-top: -10px;
		font-size: 6vw;
	}
	
	.header .server-title a:before {
		font-size: 6vw;
	}
}

@media (max-width: 420px) {
	.panel {
		height: 50px;
	}
	
	.header {
		height: 200px;
	}
	
	.menu .btn-main {
		font-size: 4.4vw;
		margin: 0;
		padding: 8px;
	}
	
	.get-ip {
		display: none;
	}
}

.panel-mini {
	height: 36px;
	width: 100%;
	background-color: purple;
	color: white;
	display: flex;
	align-items: center;
	background: #372B59;
	position: sticky;
	top: 0;
	z-index: 1;
	font-weight: 700;
}

.panel-mini .button {
	margin-left: 15px;
}

.panel-mini .state {
	margin-left: auto;
	margin-right: 15px;
}