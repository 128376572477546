.content .main {
	display: flex;
	flex-direction: column;
}

.main .row {
	display: flex;
	flex-direction: row;
	
	gap: 30px;
}

.main .top {
	margin: 0;
	margin-top: -50px;
	height: 50px;
}

@media (max-width: 700px) { 
	.main .row {
		flex-direction: column;
		margin-top: 30px;
	}
	
	.main .top {
		margin-top: -10px;
		margin-left: 10px;
	}
}

@media (min-width: 700px) { 
	.main h2 {
		flex-direction: column;
		margin-top: 50px;
	}
}

.main .items {
	margin-top: 70px;
	margin-right: 90px;
	width: 64%;
}

.main .present-item {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 15px;
	
	color: white;
	font-size: 20px;
	font-weight: 500;
	padding: 15px;
	
	user-select: none;
	position: relative;
}

.present-item.A {
	border: 2px solid #794AFF88;
	background-color: #7569BD77;
}

.present-item.B {
	border: 2px solid #FFAC4A88;
	background-color: #FBB95788;
}

.present-item.C {
	border: 2px solid #46D9A466;
	background-color: #8CF485bb;
}

.present-item a {
	filter: drop-shadow(0px 2px 0px #0000);
}

.present-item img {
	width: 70px;
	height: 70px;
}

.present-item div {
	display: flex;
	flex-direction: column;
}

.overfox-panel {
	background: linear-gradient(130deg, #0003 0%, #0004 100%);
	border: 2px solid #0001;
	padding: 20px 20px 10px 20px;
	
	color: white;
	font-size: 18px;
	font-weight: 600;
	
	display: flex;
	flex-direction: column;
}

.items .overfox {
	position: relative;
}

.main h2 {
	color: #FFF;
	filter: drop-shadow(0 4px 0 #0002);
}

.overfox-panel hr {
	border: none;
	width: 100%;
	height: 3px;
	background-color: #fff9;
}

.overfox-panel .text {
	#filter: drop-shadow(0 2px 0 #0001);
	letter-spacing: 0.4px;
	display: flex;
	flex-direction: column;
}

.overfox-panel .download {
	margin-left: auto;
	margin-right: 0;
	margin-top: 7px;
	padding: 8px 20px;
	
	font-weight: 700;
	font-size: 19px;
	
	cursor: pointer;
	user-select: none;
	
	border-radius: 15px;
	background: linear-gradient(90deg, #DD7BFF 0%, #73FFE6 100%);
	
	display: flex;
	align-items: center;
	gap: 10px;
}

.overfox-panel .download img {
	width: 30px;
}






.main .feed {
	z-index: 2;
	margin-right: 90px;
	width: 65%;
}

.main .post {
	height: auto;
	filter: none;
	display: flex;
	flex-direction: column;
}

.main .post-avatar {
	width: 46px;
	height: 46px;
	margin: auto 0;
	margin-left: 16px;
	margin-right: 16px;
	border-radius: 50px;
	#border: 2px solid #5923FE99;
}

.main .post-content {
	position: relative;
	background: #FBFBFB;
    background-position: center center;
	font-family: "Nunito";
}

.main .post-markdown {
	padding: 0px 15px 0px 15px;
}

.main .post-header {
	margin: auto -2px;
	z-index: 1;
	position: relative;
	height: 72px;
	background-color: white;
	display: flex;
	align-items: center;
}

.main .post-title {
	margin: auto 0;
	
	background: linear-gradient(45deg, #794aff 0%, #eb00ff 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.main .post-title h1 {
	margin: 0;
	color: rgba(72,56,255,1);
	font-weight: 1000;
	text-transform: uppercase;
	font-size: 22px;
}

.main .post-header-left {
	display: flex;
	flex-direction: row;
	line-height: 1.1;
	font-weight: 600;
}

.point {
	display: inline-block;
	margin: 0px 12px 2px 10px;
	border-radius: 15px;
	width: 5px;
	height: 5px;
	background-color: #eb00ff;
}

.main .post-month {
	margin-bottom: 2px;
	display: flex;
	flex-direction: column;
	color: #aaa;
	font-weight: 800;
	font-size: 14px;
	font-family: Inter;
	align-items: center;
	width: 90px;
	margin-left: auto;
	
	background: linear-gradient(45deg, #794aff 0%, #eb00ff 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.main .read-full {
	display: flex;
	width: 100%;
}

.main .read-full a {
	margin-left: auto;
	margin-right: 10px;
	margin-top: -20px;
	font-weight: 900;
	text-transform: uppercase;
	cursor: pointer;
	
	background: linear-gradient(90deg, rgba(255, 116, 216, 1) 0%, rgba(126, 232, 255, 1) 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.content .post-footer {
	height: 40px;
	width: 100%;
	background-color: #eee;
	display: flex;
	align-items: center;
}

.content .post-footer .post-replies-info {
	display: flex;
	margin-left: auto;
	margin-right: 12px;
	font-weight: 600;
	color: #999;
	width: auto;
}



.colorful-border {
	border: 2px solid #794aff;
	
	background-image: linear-gradient(white, white), linear-gradient(45deg, #794aff 0%, #eb00ff 100%);
	background-origin: border-box;
	background-clip: content-box, border-box;
}

.border-1 {
	border: double 3px transparent;
	border-radius: 5px;
}

.border-2 {
	border: double 2px transparent;
	border-radius: 3px;
}



.info {
	width: 30%;
}

.content .info {
	z-index: 2;
	margin-top: 70px;
}

.content .info-block {
	position: relative;
	margin-bottom: 30px;
	min-width: 300px;
	color: #804782;
	display: flex;
	flex-direction: column;
}

.content .info-block-topper {
	top: 0;
	height: 50px;
	width: calc(100% - 1px);
	z-index: 1;
	margin: -1px;
	background-color: #F8F8F8;
	
	align-items: center;
	display: flex;
}

.content .info-block-topper a {
	margin-left: 20px;
	font-size: 18px;
	font-weight: 700;
	
	background: linear-gradient(45deg, #794aff 0%, #eb00ff 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	
	
}

.content .font-palette {
	background: linear-gradient(90deg, rgba(255, 116, 216, 1) 0%, rgba(96, 202, 225, 1) 100%), #aaa;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.content .info-block-content {
	display: flex;
	flex-direction: column;
	background-color: #F8F8F8;
	
	padding: 15px 20px;
	font-size: 17px;
	font-weight: 300;
}

.content .info-block-footer a {
	margin-left: 15px;
	font-size: 16px;
	align-self: center;
	
	color: #999;
	font-weight: 500;
	position: absolute;
}
.content .info-block-footer {
	display: flex;
	height: 42px;
	bottom: 0;
	background-color: #eee;
	
}

.content .serverList {
	display: flex;
	flex-direction: column;
}

.content .socials {
	display: flex;
	flex-direction: row;
	gap: 15px;
}

.content .socials img {
	cursor: pointer;
}

.main .feed-info .modes {
	display: flex;
	flex-direction: column;
}

.main .feed-info .modes .row {
	display: flex;
	width: 100%;
	font-size: 17px;
	font-weight: 600;
}

.main .feed-info .mode {
	display: flex;
	flex-direction: column;
	width: 50%;
}

.main .feed-info .modes .updatedAt {
	-webkit-text-fill-color: #999;
	margin-top: 10px;
	font-size: 16px;
	font-weight: 300;
}



@media (max-width: 1200px) { 
	.main .feed {
		width: 60%;
		margin-bottom: 60px;
		margin-right: 30px;
	}
	
	.feed .post-avatar {
		margin: 0 10px;
	}
	
	.feed .post-title h1 {
		font-size: 18px;
	}
	
	.feed .post-title div {
		font-size: 14px;
	}
}

@media (max-width: 1000px) { 
	.main .feed {
		width: 80%;
		margin-right: 0;
	}
	
	.main .items {
		width: 80%;
		margin-right: 0;
	}
	
	.main .feed-info {
		width: 60%;
	}
}

@media (max-width: 700px) {
	.info-block {
		display: flex;
		flex-direction: column;
	}
	
	.sections-info {
		width: 100%;
	}
	
	.main {
		width: 102%;
	}
	
	.main .post {
		margin-right: 0;
	}
	
	.main .feed {
		width: 100%;
		margin: 0;
	}
	
	.main .items {
		width: 101%;
		margin: 0;
	}
	
	.main .info {
		width: 100%;
		margin-top: 0;
	}
	
	.main .info-block {
		margin-bottom: 15px;
	}
}